<template>
    <div class="reply-item-ctn">
        <div class="reply-item-header">
            <div class="avatar-ctn">
                <Avatar :name="replyData.userName" :url="replyData.avatar" :size="40"></Avatar>
            </div>
            <div class="user-info">
                <div class="name-pos">
                    <div class="user-name">
                       {{ replyData.userName }}
                    </div>
                   <!--  <div class="user-pos">
                        学生
                    </div> -->
                </div>
                <div class="reply-date">
                    回复于 {{  replyData.commentTime  }}
                </div>
            </div>
        </div>
        <div class="reply-content">
          {{  replyData.content}}
        </div>
        <a :href="getPostHref" target="_blank">
        <div class="post-item-ctn">
            <div class="post-item-content">
                <div class="post-title">
                    {{ replyData.title  }}
                </div>
                <div class="post-info-ctn">
                    <div class="post-info-item">
                        {{ replyData.likeCount }} 次点赞
                    </div>
                    <div class="post-info-item">
                        {{ replyData.commentCount }} 条评论
                    </div>
                </div>
            </div>
            <div v-if="replyData.coverPathCode" class="post-item-cover-ctn">
                <img class="post-item-cover" :src="replyData.coverPathCode" />
            </div>
        </div>
    </a>
    </div>
</template>

<script>
import { computed } from 'vue-demi';
import Avatar from '../../components/avatar.vue';
export default {
    components: {
        Avatar
    },
    props: {
        index: {
            default: -1
        },
        replyData: {
            default: () => ({})
        }
    },
    setup(props,context) {
        const { $router } = context.root;
        const getPostHref = computed(() => {
            if(props.replyData.id) {
                const href = $router.resolve({
                    name: 'bbsPost',
                    params: {
                        id: props.replyData.id
                    }
                });
                return href.href
            }
            return ''
        })
        return {
            getPostHref
        }
    }
}
</script>

<style lang="less" scoped>
a {
    color: inherit;
    text-decoration: none;
}
.reply-item-ctn {
    padding: 20px;
    .reply-item-header {
        display: flex;
        align-items: center;
        .user-info {
            margin-left: 10px;
            .name-pos {
                display: flex;
                align-items: center;
                .user-name {
                    color: rgba(0, 0, 0, 0.85);
                }
                .user-pos {
                    font-size: 12px;
                    color: #8590A6;
                    padding: 0px 8px;
                    margin-left: 16px;
                    position: relative;
                    z-index: 100;

                    &:before {
                        content: '';
                        position: absolute;
                        width: 6px;
                        height: 10px;
                        top: 2px;
                        left: 0px;
                        z-index: 10;
                        border-left: 0.5px solid #8590A6;
                        border-top: 0.5px solid #8590A6;
                    }

                    &:after {
                        content: '';
                        position: absolute;
                        width: 6px;
                        height: 10px;
                        bottom: 2px;
                        right: 0px;
                        z-index: 10;
                        border-right: 0.5px solid #8590A6;
                        border-bottom: 0.5px solid #8590A6;
                    }
                }
            }
        }
        .reply-date {
            font-size: 12px;
            color: rgba(0, 0, 0, 0.45);
            margin-top: 2px;
        }
    }
    .reply-content {
        color: rgba(0, 0, 0, 0.65);
        margin-top: 16px;
        margin-left: 50px;
    }
    .post-item-ctn {
        margin-top: 10px;
        display: flex;
        align-items: center;
        margin-left: 50px;
        padding: 12px;
        background: #F6F6F6;
        cursor: pointer;
        transition: .3s background ease-in-out;
        opacity: 1;
        &:hover {
            opacity: 0.8;
        }
        .post-item-content {
            min-height: 68px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 0px;
        flex: 1;
            .post-title {
                color: rgba(0, 0, 0, 0.85);
                font-size: 16px;
            }
            .post-info-ctn {
                display: flex;
                color: rgba(0, 0, 0, 0.45);
                font-size: 12px;
                .post-info-item {
                    min-width: 100px;
                }
                .post-info-item + .post-info-item {
                    margin-left: 16px;
                }
            }
            
        }
        .post-item-cover-ctn {
            margin-left: 30px;
            .post-item-cover {
                width: 118px;
                height: 68px;
                object-fit: cover;
            }
        }
       
    }
}
</style>