<template>
    <div class="post-list-ctn">
        <a-spin v-show="mainContentLoading && replyList.length == 0" :spinning="mainContentLoading">
            <div style="padding: 16px;">
                <a-skeleton active avatar title :paragraph="{ rows: 4 }" />
            </div>
        </a-spin>
        <a-spin :spinning="mainContentLoading && replyList.length !== 0" tip="正在加载中...">
            <div ref="listRef">
                <div v-for="(item, index) in replyList">
                    <ReplyItem :index="index" :replyData="item"></ReplyItem>
                </div>
            </div>
        </a-spin>
        <div v-if="!mainContentLoading && replyList.length === 0" style="padding: 50px;">
            <a-empty></a-empty>
        </div>
        <div class="pagination-ctn default-pagination">
            <a-pagination @change="handlePageChange" v-model="current" :pageSize="pageSize" :total="total" show-quick-jumper
                hideOnSinglePage style="margin: 25px;"/>
        </div>
</div>
</template>

<script>

import { onMounted, ref } from 'vue-demi';
import ReplyItem from '../components/replyItem.vue';
import { getMyCommentPaper } from '@/api/paperManage.js';
export default {
    components: {
        ReplyItem
    },
    props: {
        userInfo: {
            default: () => ({})
        }
    },
    setup(props, { root, emit }) {
        const { $route } = root;
        const current = ref(1); // 当前页
        const total = ref(0); // 总页数
        const pageSize = ref(20); // 单页数目
        const mainContentLoading = ref(true);   // 是否正在加载
        const replyList = ref([]);   // 帖子列表

        // 页数改变
        const handlePageChange = async () => {
            mainContentLoading.value = true
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
            const res = await getMyCommentPaper({
                userId: $route.params.id,
                pagesize: pageSize.value,
                pageno: current.value
            })
            if(res.success) {
                if(res.data.pageResult) {
                    total.value = res.data.pageResult.totalRows;
                    replyList.value = res.data.pageResult.rows  ? res.data.pageResult.rows.map(item => {
                        return {
                            ...item,
                            avatar: props.userInfo.avatar,
                            userName: props.userInfo.name,
                            userId: props.userInfo.id
                        }
                    }) : [];
                    emit('onChangePostCount', res.data.myPapers, total.value)
                }
                else {
                    total.value = 0;
                    replyList.value = [];
                    emit('onReplyPostCountChange', '', '')
                }
                
            }
            mainContentLoading.value = false;
            /* setTimeout(() => {
                replyList.value = [1,2,3,4,5,6,7,8,9,10]
                mainContentLoading.value = false;
                total.value = 1000;
            }, 2000) */
        }
        // 
        onMounted(() => {
            document.title = `回帖列表 - ${props.userInfo.name}`
            handlePageChange();
        })

        return {
            mainContentLoading,
            replyList,
            current,
            total,
            pageSize,
            handlePageChange
        }
    }
}
</script>

<style lang="less" scoped>
.pagination-ctn {
    //padding: 25px;
    text-align: center;
    //border-left: .5px solid #ebebeb;
    // border-right: .5px solid #ebebeb;
    //border-bottom: .5px solid #ebebeb;
}
</style>